export const baseURL = "https://metube.codderlab.com/";
export const secretKey = "0LF8bPi5BnOgl3JjLGcfhfU3N7TAk8rJ";

export const projectName = "Metube";
export const folderStructurePath = "meTube";
export const bucketName = "codderlab";
export const hostname = "https://blr1.digitaloceanspaces.com/";
export const aws_access_key_id = "DO00W6HQVBUR4T8FFXAL";
export const aws_secret_access_key =
  "1IIaXFh6Znyz1Ryu87lZXOZckV4m5jXlzOZzUrIShT4";
